.unselectedBuffer {
  box-sizing: border-box;
  background-color: white;
  color: black;
  width: 100%;
  height: 5em;
  text-align: left;
  border: red;
  border-radius: 2px;
  margin-top: 1em;
  margin-bottom: 10px;
  border: 2px solid blueviolet;
}

.selectedBuffer {
  box-sizing: border-box;
  background-color: white;
  color: black;
  width: 100%;
  text-align: left;
  margin-top: 1em;
  background-color: whitesmoke;
}
.ua_img {
  background-size: cover;
  height: 6em;
  width: 6em;
  border-radius: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ua_title {
  line-height: 1.3;
}
.ua_wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: center;
  line-height: 0.8;
  box-sizing: border-box;
  width: 100%;
}
.ua_list {
  box-sizing: border-box;
  padding-top: 1em;
  padding-bottom: 1em;
  width: 100%;
}
.ua_list :first-child {
  margin-top: 0;
}
