/* BASE APP
 * ============================== */

.Product-wrapper {
  width: 100%;
  margin: 40px 1em 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  grid-area: product;
}
.Product-wrapper-threshold {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.Product-wrapper-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.button {
  background-color: #5f00ba;
  color: white;
  border: none;
  font-size: 1.2rem;
  padding: 10px 17px;
  cursor: pointer;
}

.button:hover,
.button:focus {
  background-color: #222222;
}

.button:disabled {
  background: #bfbfbf;
  cursor: not-allowed;
}

/* CART
   * ============================== */
.cartPageGrid {
  display: grid;
  grid-template-columns: 62% 38%;
  grid-template-areas:
    "yourcart cart"
    "yourcart cart";
}

.emptyCartGrid {
  display: grid;
  width: 100%;
  grid-template-columns: 100%;
  grid-template-areas:
    "appheader"
    "yourcart"
    "footer";
}
.emptyCart {
  grid-area: yourcart;
  color: "black";
  text-align: center;
}
.qrcode {
  grid-area: qr;
  display: none;
}
.designProduct {
  box-sizing: border-box;
  display: flex;
  grid-area: design-product;
  padding: 2em;
}
.addanother {
  grid-area: addanother;
  padding-top: 2em;
}
.appheader {
  grid-area: appheader;
}
.discount {
  float: right;
}
.user-designs {
  display: flex;
  flex-direction: column;
  display: border-box;
}
@media (max-width: 1156px) {
  .discount {
    float: left;
  }
}

.cartLeftGrid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: minmax(50px, 95px);
  grid-template-areas:
    "appheader appheader"
    "design-product design-product"
    "addanother addanother"
    "footer footer";
}
.Cart {
  grid-area: cart;

  height: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;

  color: black;
}

@media (max-width: 1024px) {
  .cartPageGrid {
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas:
      "yourcart"
      "cart"
      "footer";
  }

  .cartLeftGrid {
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas:
      "appheader"
      "design-product"
      "addanother"
      "cart";
  }

  .designProduct {
    flex-direction: row;
    justify-items: center;
  }
}

@media (max-width: 768px) {
  .designProduct {
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }
}

.cartLeftGrid {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas:
    "appheader"
    "design-product"
    "addanother"
    "cart"
    "footer";
}

.CartMobile {
  position: relative;
  height: 100%;

  background-color: transparent;
  display: flex;
  flex-direction: column;

  color: black;
}

.Cart--open {
  transform: translateX(0);
}

.Cart__header {
  padding: 20px;

  flex: 0 0 auto;
  display: inline-block;
  color: black;
}

.Cart__line-items {
  flex: 1 0 auto;
  margin: 0;
  padding: 20px;
  color: black;
}

.Cart__footer {
  padding: 20px;
  color: black;
  flex: 0 0 auto;
}

.Cart-info {
  padding: 15px 20px 10px;
  color: black;
}

.Cart-info__total {
  float: left;
  text-transform: uppercase;
  color: black;
}

.Cart-info__small {
  font-size: 11px;
  color: black;
}

.Cart-info__pricing {
  float: right;
  color: black;
}

.pricing {
  margin-left: 5px;
  font-size: 16px;
  color: black;
}

/* LINE ITEMS
   * ============================== */
.Line-item {
  margin-bottom: 20px;
  overflow: hidden;
  backface-visibility: visible;
  min-height: 65px;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  color: black;
}

.Line-item__img {
  /*
  width: 65px;
  height: 65px;
  border-radius: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  position: absolute;
  */
}

.Line-item__content {
  width: 100%;
}

.Line-item__content-row {
  display: inline;
  width: 100%;
  position: relative;
}

.Line-item__variant-title {
  font-weight: bold;
  font-size: 11px;
  line-height: 17px;
  color: black;
}

.Line-item__title {
  color: black;
  font-size: 15px;
  font-weight: 400;
}

.Line-item__price {
  line-height: 23px;
  float: right;
  font-weight: bold;
  font-size: 15px;
}

.Line-item__quantity-container {
  border: 1px solid #5f00ba;
  background-color: white;
  float: left;
  border-radius: 3px;
}

.Line-item__quantity-update {
  color: #5f00ba;
  display: block;
  float: left;
  height: 21px;
  line-height: 16px;
  font-family: monospace;
  width: 25px;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
}

.Line-item__quantity-update-form {
  display: inline;
}

.Line-item__quantity {
  color: #5f00ba;
  width: 38px;
  height: 21px;
  line-height: 23px;
  font-size: 15px;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  background: transparent;
  border-left: 1px solid #5f00ba;
  border-right: 1px solid #5f00ba;
  display: block;
  font-weight: bold;
  float: left;
  padding: 0;
  border-radius: 0;
}

.Line-item__remove {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: 0;
  font-size: 20px;
  top: -4px;
  opacity: 0.5;
}

.Line-item__remove:hover {
  opacity: 1;
  cursor: pointer;
}

/* PRODUCTS
   * ============================== */
.Product {
  flex: 0 1 31%;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 3%;
  color: black;
}

.Product__title {
  font-size: 1.3rem;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  opacity: 0.7;
  color: black;
}

.Product__titleSmall {
  font-size: 1.2rem;
  margin-top: 0;
  margin-bottom: 0.4rem;
  opacity: 0.7;
  color: black;
}

.Product__price {
  display: block;
  font-size: 1.1rem;
  opacity: 0.5;
  margin-bottom: 0.4rem;
  color: black;
}

.Product__option {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  color: black;
}

.Product__quantity {
  display: block;
  width: 15%;
  margin-bottom: 10px;
  color: black;
}

.error {
  display: block;
  font-size: 15px;
  padding: 10px;
  position: relative;
  min-height: 2.64286em;
  background: #fbefee;
  color: #c23628;
}
