.threshcontainer {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 300px;
}
.freeshipping {
  font-weight: bold;
}
.innerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  padding-left: 3em;
  padding-right: 3em;
  padding-bottom: 3em;
  font-family: Avenir Next;
}
