.sidebar {
  font-family: "Cabin", sans-serif;
  grid-area: sidebar;
  background-color: white;
  min-height: 100vh;
  width: 100%;
  font-size: 14px;
  border-radius: 0px 0px 0px 0px;
}
@media only screen and (max-width: 1317px) {
  .sidebar {
    width: 100%;
    min-height: 20vh;
  }
}

.__continueBtn {
  box-shadow: none;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2989d8+0,ed2faa+55 */
  background: #5f00ba; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #5f00ba 0%,
    #ed2faa 55%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #5f00ba 0%,
    #ed2faa 55%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #5f00ba 0%,
    #ed2faa 55%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5f00ba', endColorstr='#ed2faa',GradientType=1 ); /* IE6-9 */
}
