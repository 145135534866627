@import url("https://fonts.googleapis.com/css?family=Cabins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
body {
  background: #fff2f1;
  color: #fff;
  font-family: "Noto Sans", sans-serif;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  z-index: 0;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  transition: all 500ms;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.cardShadow {
  box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem,
    rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
  position: relative;
  z-index: 10;
}

.card:hover {
  box-shadow: rgba(2, 8, 20, 0.1) 0px 0.35em 1.175em,
    rgba(2, 8, 20, 0.08) 0px 0.175em 0.5em;
  transform: translateY(-3px) scale(1.1);
  z-index: 10;
}
