.template-grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-auto-rows: 300px;
}

@media screen and (min-width: 680px) {
  .card-tall {
    grid-row: span 2 / auto;
  }

  .card-wide {
    grid-column: span 2 / auto;
    z-index: 0;
  }
}

@media screen and (max-width: 680px) {
  .template-grid {
    grid-template-columns: 100%;
  }
}
