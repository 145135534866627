.container {
  display: grid;

  box-sizing: border-box;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    "header header header"
    "viz     viz    viz "
    ".     record    .";
  width: 100%;
  height: 100%;

  color: black;
  align-items: center;
  background-color: #fff2f1;
}
.mainBtn {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  z-index: 1;
  border: none;
  background: #ffffff;
  border-radius: 100vh;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}
.waver {
  grid-area: viz;
  width: 70vw;
  height: 70vw;
  text-align: cemter;
  border-radius: 100vh;
  justify-self: center;
  background: #ffffff;
}
.recordSymbol {
  position: relative;
  margin: 0 auto;
  content: "";
  z-index: 100;
  width: 31px;
  height: 31px;
  border-radius: 100vh;
  background: #f50057;
}
.smallBtn {
  width: 66px;
  height: 66px;
  border: none;
  border-radius: 100vh;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}
.smallLabel {
  width: 30px;
  height: 19px;
  left: 60px;
  top: 730px;
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

.bigLabel {
  position: relative;
  padding-top: 1em;
  width: 65px;
  height: 24px;
  margin: 0 auto;
  color: #f50057;
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.header {
  grid-area: header;
  position: absolute;
  width: 100%;
  box-shadow: none;
  background: #ffffff;
}
.symbol {
  width: 60px;
  height: 40px;
}
.headerTitle {
  position: relative;
  text-align: center;
  color: #5f00ba;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
}
.description {
  position: relative;
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.01em;

  color: #000000;
}

.lowBar {
  box-sizing: border-box;
  grid-area: record;
  display: flex;
  flex-direction: column;
}

.description .a {
  text-decoration-line: underline;
  color: #5f00ba;
}
.duration {
  width: 103px;
  height: 58px;
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
}
.viz {
  position: relative;
}
