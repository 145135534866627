@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
.App {
  text-align: center;

  background: #fff2f1;
  font-size: 12px;
}

@media only screen and (max-width: 1024px) {
  .App {
    display: block;
  }
}

@media only screen and (min-width: 1024px) {
  .App {
    display: grid;
    height: 100%;
    grid-template-columns: 30% 70%;
  }
}

.App-logo {
  height: 2%;
}

.App-header {
  background-color: #fff2f1;
  height: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}
.templateSelect {
  grid-area: templates;

  padding-left: 4em;
  padding-right: 4em;
}
@media (min-width: 1318px) {
  .templateSelectGrid {
    text-align: center;
    display: grid;
    grid-template-columns: 30% 70%;

    grid-template-areas:
      "sidebar templates "
      "sidebar templates "
      "sidebar footer ";
  }
}

@media (max-width: 1317px) {
  .templateSelectGrid {
    text-align: center;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 2em;
    grid-template-areas:
      "sidebar"
      "templates"
      "footer";
  }

  .templateSelect {
    grid-area: templates;
    padding-left: 2em;
    padding-right: 2em;
  }
}

.designContainer {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-column-gap: 2em;
  grid-template-areas:
    "sidebar design"
    "sidebar footer";
}
@media (max-width: 1360px) {
  .designContainer {
    grid-template-columns: 1fr;
    grid-row-gap: 2em;
    grid-template-areas:
      "design"
      "sidebar"
      "sidebar"
      "sidebar"
      "footer";
  }
}

.hidden {
  display: none;
}

.design {
  grid-area: design;
  align-self: center;
  width: auto;
  max-width: 100%;
  margin-top: 2em;
}
.framed {
  border-image: url("./assets/frame_transparent.png") 3% 5% stretch;
  -o-border-image: url("./assets/frame_transparent.png") 3% 5% stretch;
  -moz-border-image: url("./assets/frame_transparent.png") 3% 5% stretch;
  -webkit-border-image: url("./assets/frame_transparent.png") 3% 5% stretch;
}
.framed-portrait {
  border-image: url("./assets/frame_transparent.png") 3% 3% stretch;
  -o-border-image: url("./assets/frame_transparent.png") 3% 3% stretch;
  -moz-border-image: url("./assets/frame_transparent.png") 3% 3% stretch;
  -webkit-border-image: url("./assets/frame_transparent.png") 3% 3% stretch;
}
.footer {
  box-sizing: border-box;
  padding: 2em;
  text-align: center;
  align-self: center;
  height: 100%;
}
